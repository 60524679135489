
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isOpen = ref(false);

    const click = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      click,
      isOpen,
    };
  },
});
