
import { defineComponent } from 'vue';
import HighlightBox from '@/components/HighlightBox.vue';

export default defineComponent({
  name: 'Startseite',
  components: {
    HighlightBox,
  },
});
