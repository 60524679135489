<template>
  <div class="dropdown-container" :class="{ open: isVisible }">
    <div class="dropdownbutton dropdown-toggle" ref="button" @click="click" v-html="title"></div>
    <div class="dropdown" v-if="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isOpen = ref(false);

    const click = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      click,
      isOpen,
    };
  },
});
</script>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
  z-index: 99;
  border: 1px solid #eee;
  background-color: #fff;
  padding: 5px;
  //box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 13px 27px -5px, rgba(0, 0, 0, 0.1) 0px 8px 16px -8px;
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 0.2s ease;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
}

.dropdownbutton {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

  cursor: pointer;

  color: $font-color;
  text-transform: uppercase;

  span {
    border: 0px solid red;
    line-height: 1px;
    height: 1px;
    position: relative;
    top: 5px;
    margin-right: 5px;
  }
  &:hover {
    color: $primary-red;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}
</style>
