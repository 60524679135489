<style lang="scss" scoped>
.button {
  border: 1px solid red;
}
.collapser {
  border: 1px solid red;
}
</style>

<template>
  <div class="test">
    <img src="/assets/img/img-content/hauptblatt/exklusivplatzierung-v1.png" />

    <DropDown :title="'<span class=\'material-icons\'>settings</span>Service'">
      <router-link :to="{ name: 'calculator' }" class="dropdown-item">
        <span>Tarif <strong>Rechner</strong></span>
      </router-link>
      <router-link :to="{ name: 'Allgemeine Informationen' }" class="dropdown-item">
        <span>Allgemeine Informationen</span>
      </router-link>
      <router-link :to="{ name: 'Spezifikationen' }" class="dropdown-item">
        <span>Sepzifikationen</span>
      </router-link>
    </DropDown>
    <hr />
    <NavLink to="/">Test</NavLink>
    <CollapsibleButton link="test">
      <div class="button">Klick mich!</div>
    </CollapsibleButton>
    <Collapsible link="test">
      <div class="collapser">ich bin ein collapsible!</div>
    </Collapsible>
    <hr />
    <CollapsibleButton link="test2">
      <div class="button">Klick mich!</div>
    </CollapsibleButton>
    <Collapsible link="test2" open>
      <div class="collapser">ich bin ein collapsible!</div>
    </Collapsible>
  </div>
  <Debug></Debug>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import Debug from '@/components/util/Debug.vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';
import DropDown from '@/components/util/DropDown.vue';

export default defineComponent({
  name: 'Test',
  components: {
    Debug,
    CollapsibleButton,
    Collapsible,
    DropDown,
  },
  setup() {
    const test = ref('');
    return {
      date: new Date(),
      test,
    };
  },
});
</script>
