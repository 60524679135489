
import { defineComponent, ref } from 'vue';

import Debug from '@/components/util/Debug.vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';
import DropDown from '@/components/util/DropDown.vue';

export default defineComponent({
  name: 'Test',
  components: {
    Debug,
    CollapsibleButton,
    Collapsible,
    DropDown,
  },
  setup() {
    const test = ref('');
    return {
      date: new Date(),
      test,
    };
  },
});
