
import { computed, defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  setup() {
    const store = useStore();
    const showState = ref(false);
    const showRoute = ref(false);
    const route = useRoute();
    return {
      width: computed(() => store.state.windowsize),
      menustate: computed(() => store.state.menustate),
      searchstate: computed(() => store.state.searchstate),
      showState,
      store,
      route,
      showRoute,
      toggleRoute: () => (showRoute.value = !showRoute.value),
      toggleState: () => (showState.value = !showState.value),
    };
  },
});
